import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { version } from "../../../../package.json"
import "../../../_metronic/_assets/sass/pages/login/classic/login-2.scss";

import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { useToken } from "../../hooks/useToken";

export const AuthPage = () => {
    const { token } = useToken()

    return (
        <div className="d-flex flex-column flex-root">
            <div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                <div className="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
                    <p style={{ position: 'absolute', paddingTop: 10, left: 15 }} >V {version}</p>
                    <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35">
                        {/* <a href="/#" className="text-center pt-2">
                            <img src={toAbsoluteUrl('/media/logos/logo-personalite.png')} style={{ width: 200 }} alt="" />
                        </a> */}
                        <Switch>
                            <Route path="/auth/login" component={Login} />
                            <Route path="/auth/forgot" component={ForgotPassword} />
                        </Switch>

                        {
                            !token &&
                            <Redirect to="/auth/login" />
                        }

                    </div>
                </div>
                <div className="content order-1 order-lg-2 d-flex flex-column w-100 p-0" style={{ backgroundColor: '#B1DCED', position: 'relative' }}>
                    {/* <div className="d-flex flex-column justify-content-center text-center  pt-lg-0 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
                        <p className="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70 pt-10">
                            Conectando pessoas e sistemas na era <br /> da transformação digital.
                        </p>
                    </div> */}
                    <div
                        className="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                        style={{
                            backgroundImage: `url(${'/media/bg/sc-home1-bg.png'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    ></div>

                    <img 
                        alt={"personalite logo"}
                        src={'/media/logos/logo-personalite2.png'} 
                        style={{ 
                            position: 'absolute',
                            left: 100,
                            top: 100
                        }}    
                    />
                </div>
            </div>
        </div>
    )
}