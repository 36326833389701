export type HttpRequest = {
    url: string
    method: 'post' | 'get' | 'put' | 'delete'
    body?: any
    headers?: any
  }
  
  export interface HttpClient<R = any> {
    request: (data: HttpRequest) => Promise<HttpResponse<R>>
  }

  export enum HttpMethod {
    POST = 'post',
    GET = 'get',
    PUT = 'put',
    DELETE = 'delete'
  }
  
  export enum HttpStatusCode {
    ok = 200,
    noContent = 204,
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    serverError = 500
  }

  export type HttpResponsePattern<T = any> = {
    status: string,
    message: string,
    data: T
  }
  
  export type HttpResponse<T = any> = {
    statusCode: HttpStatusCode
    body: HttpResponsePattern<T>
  }