import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert2";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { DeleteUser } from "../../../domain/usecases/users/delete-user";
import { ListUserModel } from "../../../domain/models/user/list-user-model";
import { makeRemoteUserList } from "../../../main/factories/usecases/users/remote-list-user-factory";

import { Button } from "../../components/button";
import { Table } from "../../components/table";
import { Input } from "../../components/input";
import { UpdateUserPassword } from "../../../domain/usecases/users/update-user-password";

type ListUsersProps = {
  remoteDeleteUser: DeleteUser.Domain;
  remoteUpdateUserPassword: UpdateUserPassword.Domain;
};

export const ListUsers: React.FC<ListUsersProps> = ({
  remoteDeleteUser,
  remoteUpdateUserPassword,
}) => {
  const refForm = useRef<FormHandles>(null);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<ListUserModel[]>([]);

  useEffect(() => {
    initPage();
  }, []);

  function initPage() {
    const listUsers = makeRemoteUserList();

    listUsers
      .execute()
      .then((response) => {
        const { data } = response;
        setUsers(data);
      })
      .catch((error) => {});
  }

  async function deleteUser(id: string) {
    try {
      await remoteDeleteUser.execute(id);
      initPage();
    } catch (error) {
      console.log(error);
      swal.fire({
        title: "Não foi possivel remover este usuário",
        icon: "error",
        width: 300,
        timer: 3000,
      });
    }
  }

  function handleDelete(id: string) {
    swal
      .fire({
        title: "Deletar usuário?",
        text: "Apos deletar não será possivel reverter",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Deletar",
        denyButtonText: `Cancelar`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUser(id);
        }
      });
  }

  function handleUpdatePassword(id: string) {
    swal
      .fire({
        title: "Resetar senha do usuário?",
        text: "Apos resetar não será possivel reverter",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Resetar",
        denyButtonText: `Cancelar`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          resetUserPassword(id);
        }
      });
  }

  async function resetUserPassword(id: string) {
    try {
      await remoteUpdateUserPassword.execute({ id });
    } catch (error) {
      console.log(error);
      swal.fire({
        title: "Não foi possivel restar a senha deste usuário",
        icon: "error",
        width: 300,
        timer: 3000,
      });
    }
  }

  function filterUsers(user: ListUserModel) {
    const searchArr = search.trim().split(" ");
    const isExistArr: boolean[] = [];

    if (searchArr.length > 0) {
      searchArr.forEach((value) => {
        let isExist = false;
        let valueUpperCase = value.toUpperCase();

        if (user.name.toUpperCase().includes(valueUpperCase)) isExist = true;

        if (user.username.toUpperCase().includes(valueUpperCase))
          isExist = true;

        if (user.mail.toUpperCase().includes(valueUpperCase)) isExist = true;

        if (user.active && "ATIVO".includes(valueUpperCase)) isExist = true;

        if (!user.active && "INATIVO".includes(valueUpperCase)) isExist = true;

        if (isExist) isExistArr.push(isExist);
      });
    }

    return isExistArr.includes(true);
  }

  let filtered = users.filter((user) => filterUsers(user));

  return (
    <Form onSubmit={() => {}} ref={refForm}>
      <Col>
        <Card style={{ padding: 16, minHeight: "100%" }}>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col md={2}>
              <Button
                type="button"
                style={{ width: "100%" }}
                className={`btn btn-primary`}
                icon="fas fa-search"
                title="Pesquisar"
                onClick={initPage}
              />
            </Col>
            <Col md={2}>
              <Link to="/users/add">
                <Button
                  type="button"
                  style={{ width: "100%" }}
                  className={`btn btn-success`}
                  icon="fas fa-plus"
                  title="Novo usuário"
                />
              </Link>
            </Col>
          </Row>

          <br />

          <Row style={{ justifyContent: "space-between" }}>
            <Col>
              <Input
                type="text"
                placeholder="Pesquisar . . ."
                solid={true}
                name="inputCustumSelect"
                inputIcon="fas fa-search icon-lg"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(event.currentTarget.value)
                }
              />
            </Col>
          </Row>

          <Table
            //@ts-ignore
            values={filtered}
            limit={10}
            pagination={true}
            rows={(row: ListUserModel) => [
              {
                name: "NOME",
                refName: "name",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "8%", minWidth: 50 }}
                  >
                    <span>{row.name}</span>
                  </td>
                ),
              },
              {
                name: "USERNAME",
                refName: "username",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "5%", minWidth: 25 }}
                  >
                    <span>{row.username}</span>
                  </td>
                ),
              },
              {
                name: "EMAIL",
                refName: "mail",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "5%", minWidth: 25 }}
                  >
                    <span>{row.mail}</span>
                  </td>
                ),
              },
              {
                name: "DOCUMENTO",
                refName: "document",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "5%", minWidth: 25 }}
                  >
                    <span>{row.document}</span>
                  </td>
                ),
              },
              {
                name: "ACESSO",
                refName: "role",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "5%", minWidth: 25 }}
                  >
                    <span>
                      {row.role === "admin" ? "Adminitrador" : "Investidor"}
                    </span>
                  </td>
                ),
              },
              {
                name: "STATUS",
                refName: "active",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "5%", minWidth: 25 }}
                  >
                    <Badge color={row.active ? "success" : "danger"}>
                      {row.active ? "Ativo" : "Inativo"}
                    </Badge>
                  </td>
                ),
              },
              {
                name: "AÇÕES",
                refName: "",
                sorteble: true,
                cell: (props) => (
                  <td
                    className="datatable-cell"
                    key={props.index}
                    style={{ width: "10%", minWidth: 25 }}
                  >
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
                    >
                      <Link to={`/users/edit/${row.id}`}>
                        <i className="fas fa-pen text-info mr-5" />
                      </Link>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">Resetar Senha</Tooltip>
                      }
                    >
                      <span>
                        <i
                          onClick={() => handleUpdatePassword(row.id)}
                          style={{ cursor: "pointer" }}
                          className="fas fa-unlock text-warning mr-5"
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Deletar</Tooltip>}
                    >
                      <span>
                        <i
                          onClick={() => handleDelete(row.id)}
                          style={{ cursor: "pointer" }}
                          className="fas fa-trash text-danger mr-5"
                        />
                      </span>
                    </OverlayTrigger>
                  </td>
                ),
              },
            ]}
          />
        </Card>
      </Col>
    </Form>
  );
};

type BadgeProp = {
  color: "success" | "danger";
  children: JSX.Element | string;
};

const Badge: React.FC<BadgeProp> = ({ color, children }) => {
  return (
    <span
      className={`label font-weight-bold label-lg label-light-${color} label-inline`}
    >
      {children}
    </span>
  );
};
