import { ListUsers } from "../../../domain/usecases/users/list-users"
import { ErrorsValidade } from "../../../domain/validate/errors-validade"
import { HttpClient, HttpMethod, HttpResponsePattern, HttpStatusCode } from "../../protocols/http/http-client"
import { makeBearerToken } from "../../../main/factories/shared/makeBearerToken"

export class RemoteUsersList implements ListUsers {
    private errors = new ErrorsValidade()

    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<ListUsers.Model>
    ) { }

    async execute(): Promise<HttpResponsePattern<ListUsers.Model>> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: HttpMethod.GET,
            headers: makeBearerToken(),
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
                return httpResponse.body
            case HttpStatusCode.badRequest:
                throw this.errors.badRequest(httpResponse.body.message)
            case HttpStatusCode.unauthorized:
                throw this.errors.unauthorized()
            case HttpStatusCode.notFound:
                throw this.errors.forbidden(httpResponse.body.message)
            default:
                throw this.errors.unexpected()
        }
    }
}