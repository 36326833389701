import {
  HttpClient,
  HttpMethod,
  HttpResponsePattern,
  HttpStatusCode,
} from "../../protocols/http/http-client";
import { ErrorsValidade } from "../../../domain/validate/errors-validade";
import { UpdateUserPassword } from "../../../domain/usecases/users/update-user-password";
import { makeBearerToken } from "../../../main/factories/shared/makeBearerToken";

export class RemoteUpdatePasswordUser implements UpdateUserPassword.Domain {
  private errors = new ErrorsValidade();

  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<boolean>
  ) {}

  async execute({
    id,
  }: UpdateUserPassword.Params): Promise<HttpResponsePattern<boolean>> {
    const httpResponse = await this.httpClient.request({
      url: this.url + "/" + id + "/password",
      method: HttpMethod.PUT,
      headers: makeBearerToken(),
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw this.errors.badRequest(httpResponse.body.message);
      case HttpStatusCode.unauthorized:
        throw this.errors.unauthorized();
      case HttpStatusCode.notFound:
        throw this.errors.forbidden(httpResponse.body.message);
      default:
        throw this.errors.unexpected();
    }
  }
}
