import { Switch } from "react-router-dom";
import BasePage from "./basePages.routes";

import { Layout } from "../../_metronic/layout";
import { AuthPage } from "../../presentation/pages/auth";

import { useGlobalStoreContext } from "../../store/store"
import { useEffect, useState } from "react";

export function Routes() {
    const [isAuth, setIsAuth] = useState(false)
    const { store } = useGlobalStoreContext()

    useEffect(() => {
        const token = sessionStorage.getItem("jwt")
        if (token)
            setIsAuth(true)
        else
            setIsAuth(false)

    }, [store])

    return (
        <Switch>
            {
                isAuth ?
                    <Layout>
                        <BasePage />
                    </Layout>
                    :
                    <AuthPage />
            }
        </Switch>
    );
}