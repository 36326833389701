import { HttpClient, HttpMethod, HttpResponsePattern, HttpStatusCode } from "../../protocols/http/http-client";
import { ErrorsValidade } from "../../../domain/validate/errors-validade";
import { CreateUser } from "../../../domain/usecases/users/create-user";
import { makeBearerToken } from "../../../main/factories/shared/makeBearerToken";

export class RemoteCreateUser implements CreateUser.Domain {
    private errors = new ErrorsValidade()

    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<CreateUser.Model>
    ) { }

    async execute(model: CreateUser.Params): Promise<HttpResponsePattern<CreateUser.Model>> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: HttpMethod.POST,
            body: model,
            headers: makeBearerToken(),
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
                return httpResponse.body
            case HttpStatusCode.badRequest:
                throw this.errors.badRequest(httpResponse.body.message)
            case HttpStatusCode.unauthorized:
                throw this.errors.unauthorized()
            case HttpStatusCode.notFound:
                throw this.errors.forbidden(httpResponse.body.message)
            default:
                throw this.errors.unexpected()
        }
    }
}