import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Form } from "@unform/web"
import { FormHandles } from "@unform/core";

import { Input } from '../../components/input'

export function ForgotPassword() {
    const formRef = useRef<FormHandles>(null)
    const [loading] = useState(false)

    return (
        <div className="d-flex flex-column-fluid flex-column flex-center">
            <Form
                className="login-form login-signin py-11"
                ref={formRef}
                onSubmit={() => {}}
            >
                <div className="text-center pb-8">
                    <h3 className="font-weight-bolder text-dark font-size-h3 font-size-h3-lg">Recuperar Senha</h3>
                </div>
                <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Usuário</label>
                    <Input
                        className="h-auto py-7 px-6 rounded-lg bg-secondary"
                        solid
                        type="text"
                        name="username"
                        //@ts-ignore
                        autocomplete="off"
                        textError={true}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">E-mail</label>
                    </div>
                    <Input
                        className="h-auto py-7 px-6 rounded-lg bg-secondary"
                        solid
                        type="mail"
                        name="mail"
                        //@ts-ignore
                        autocomplete="off"
                        textError={true}
                        onKeyDown={(event) => event.key === 'Enter'}
                    />
                </div>
                <div className="text-center pt-2">
                    <button style={{ background: 'transparent', border: 'none' }}>
                        <Link to="/auth/login" className="btn btn-light-dark font-weight-bold font-size-h6 px-8 py-4 my-3">
                            <span>Voltar</span>
                        </Link>
                    </button>
                    <button className="btn btn-dark font-weight-bold mr-2 font-weight-bolder font-size-h6 px-8 py-4 my-3">
                        <span>Recuperar</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </Form>
        </div>
    );
}
