import { HttpClient, HttpMethod, HttpResponsePattern, HttpStatusCode } from "../../protocols/http/http-client";
import { ErrorsValidade } from "../../../domain/validate/errors-validade";
import { DeleteUser } from "../../../domain/usecases/users/delete-user";
import { makeBearerToken } from "../../../main/factories/shared/makeBearerToken";

export class RemoteDeleteUser implements DeleteUser.Domain {
    private errors = new ErrorsValidade()

    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<any>
    ) { }

    async execute(id: string): Promise<HttpResponsePattern<any>> {
        const httpResponse = await this.httpClient.request({
            url: this.url + "/" + id,
            method: HttpMethod.DELETE,
            headers: makeBearerToken(),
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
                return httpResponse.body
            case HttpStatusCode.badRequest:
                throw this.errors.badRequest(httpResponse.body.message)
            case HttpStatusCode.unauthorized:
                throw this.errors.unauthorized()
            case HttpStatusCode.notFound:
                throw this.errors.forbidden(httpResponse.body.message)
            default:
                throw this.errors.unexpected()
        }
    }
}