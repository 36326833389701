import { Link, useHistory } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { useGlobalStoreContext } from "../../../store/store";

export function UserProfileDropdown() {
  const { setStore, store } = useGlobalStoreContext()
  const history = useHistory()

  function logoff() {
    sessionStorage.removeItem('jwt')
    history.push('/auth/login')

    setStore({
      token: '',
      user: {
        nome: '',
        username: ''
      }
    })
  }

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Oi,
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {store.user.nome}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {store.user.nome && store.user.nome.length > 0 ? store.user.nome[0] : ''}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          aria-labelledby="dropdown-toggle-user-profile"
          className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        >

          <div className="navi navi-spacer-x-0 pt-5">
            <Link to="/profile" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    Meu Perfil
                  </div>
                  <div className="text-muted">
                    Configure sua conta
                  </div>
                </div>
              </div>
            </Link>

            <div className="navi-separator mt-3"></div>

            <div className="navi-footer  px-8 py-5">
              <button
                className="btn btn-light-primary font-weight-bolder px-8 py-4"
                onClick={logoff}>
                <span>Sair</span>
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}