import { HttpRequest, HttpResponse, HttpClient } from '../../data/protocols/http/http-client'

import axios, { AxiosResponse } from 'axios'

export class AxiosHttpClient implements HttpClient {
  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers
      })
    } catch (error: any) {
        axiosResponse = error.response
    }

    return {
      statusCode: axiosResponse ? axiosResponse.status : 500,
      body: axiosResponse ? axiosResponse.data : null
    }
  }
}