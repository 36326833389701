export enum API_NAMES {
  API_GATEWAY = "API_GATEWAY",
}

export const urls = {
  //API_GATEWAY: 'http://localhost:8001'
  API_GATEWAY:
    process.env.REACT_APP_URL_API_GATEWAY === undefined
      ? ""
      : process.env.REACT_APP_URL_API_GATEWAY,
};
