import { useContext, createContext, useState, useEffect } from "react";
import { GlobalStore } from "./models/global-model";

const InitialStore: GlobalStore.StoreModel = {
    user: {
        username: "",
        nome: ""
    },
    token: ""
}

const InitialGlobalStore: GlobalStore.ProviderModel = {
    store: InitialStore,
    setStore: () => {}
}

const GlobalStoreContext = createContext<GlobalStore.ProviderModel>(InitialGlobalStore)

export const useGlobalStoreContext = () => useContext(GlobalStoreContext)

export const StoreProvider: React.FC = ({ children }) => {
    const [store, setStore] = useState(InitialStore)

    useEffect(() => {
        let state = sessionStorage.getItem('state') || ''
        if (state) {
            state = Buffer.from(state, 'base64').toString()

            if (state) {
                const globalState = JSON.parse(state) as GlobalStore.StoreModel

                setStore({
                    token: globalState.token,
                    user: {
                        nome: globalState.user.nome,
                        username: globalState.user.username
                    }
                })
            }
        }

    }, [])

    useEffect(() => {
        const state = Buffer.from(JSON.stringify(store)).toString('base64')
        sessionStorage.setItem('state', state)
    }, [store])

    return (
        <GlobalStoreContext.Provider value={{ store, setStore }}>
            {children}
        </GlobalStoreContext.Provider>
    )
}