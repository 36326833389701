import { CreateUserPage } from "../../../../presentation/pages/users/create-user";
import { makeRemoteListFund } from "../../usecases/funds/remote-list-fund-factory";
import { makeRemoteCreateUser } from "../../usecases/users/remote-create-user-factory";
import { makeRemoteGetUser } from "../../usecases/users/remote-get-user-factory";
import { makeRemoteUpdateUser } from "../../usecases/users/remote-update-user-factory";

export const makeCreateUserPage = () => {
  return (
    <CreateUserPage
      remoteGetUser={makeRemoteGetUser()}
      remoteUpdateUser={makeRemoteUpdateUser()}
      remoteCreateUser={makeRemoteCreateUser()}
      remoteListFund={makeRemoteListFund()}
    />
  );
};
