import { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert2";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import Select from "react-select";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { GetUser } from "../../../domain/usecases/users/get-users";
import { UpdateUser } from "../../../domain/usecases/users/update-user";
import { CreateUser } from "../../../domain/usecases/users/create-user";
import { ListFunds } from "../../../domain/usecases/funds/list-funds";
import { documentMask } from "../../../main/utils/masks";

const roleOptions = [
  { value: "investor", label: "Investidor" },
  { value: "admin", label: "Administrador" },
];

type pathParams = {
  id: string;
};

type CreateUserProps = {
  remoteGetUser: GetUser.Domain;
  remoteUpdateUser: UpdateUser.Domain;
  remoteCreateUser: CreateUser.Domain;
  remoteListFund: ListFunds.Domain;
};

type OptionSelect = {
  label: string;
  value: string;
};

export const CreateUserPage = ({
  remoteGetUser,
  remoteUpdateUser,
  remoteCreateUser,
  remoteListFund,
}: CreateUserProps) => {
  const history = useHistory();
  const { id } = useParams<pathParams>();
  const refForm = useRef<FormHandles>(null);
  const [role, setRole] = useState<OptionSelect | null>({
    label: "Investidor",
    value: "investor",
  });
  const [fundOptions, setFundOptions] = useState<OptionSelect[]>([]);
  const [fundsSelected, setFoundsSelected] = useState<OptionSelect[]>([]);

  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);

  const getUser = useCallback(async () => {
    if (id) {
      const { data } = await remoteGetUser.execute({ id });
      refForm.current?.setData(data);

      const [dtBirth] = data.dtBirth.split("T");
      refForm.current?.setFieldValue("dtBirth", dtBirth);

      setDocument(data.document);

      const option = roleOptions.find((role) => role.value === data.role);
      if (option) setRole(option);

      const funds = data.funds.reduce((arr: OptionSelect[], fund) => {
        const exists = arr.some((item) => item.value === fund.document);
        if (!exists) {
          arr.push({
            value: fund.document,
            label: fund.name,
          });
        }

        return arr;
      }, []);

      setFoundsSelected(funds);
    }
  }, [remoteGetUser, id]);

  const getFunds = useCallback(async () => {
    try {
      const { data } = await remoteListFund.execute();

      const funds = data.reduce((arr: OptionSelect[], fund) => {
        const exists = arr.some((item) => item.value === fund.document);
        if (!exists) {
          arr.push({
            value: fund.document,
            label: fund.name,
          });
        }

        return arr;
      }, []);

      setFundOptions(funds);
    } catch (error) {
      console.log(error);
    }
  }, [remoteListFund]);

  const initPage = useCallback(async () => {
    await getFunds();
    await getUser();
  }, [getUser, getFunds]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  function save() {
    if (id) updateUser();
    else createUser();
  }

  async function createUser() {
    try {
      const model = getDataForm() as CreateUser.Params;

      setLoading(true);
      await remoteCreateUser.execute({
        ...model,
        dtBirth: `${model.dtBirth} 03:00:00`,
        password: "gustavo",
        funds: fundsSelected.map((f) => f.value.toString()),
        role: role ? role.value : "investor",
      });

      swal.fire({
        title: "Usuário criado com sucesso",
        icon: "success",
        width: 300,
        timer: 3000,
      });

      history.push("/users");

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function updateUser() {
    try {
      let model = getDataForm() as UpdateUser.Params["model"];

      setLoading(true);

      model = {
        ...model,
        dtBirth: `${model.dtBirth} 03:00:00`,
        password: "gustavo",
        funds: fundsSelected.map((f) => f.value.toString()),
        role: role ? role.value : "investor",
      };

      await remoteUpdateUser.execute({ id, model });

      swal.fire({
        title: "Usuário atualizado com sucesso",
        icon: "success",
        width: 300,
        timer: 3000,
      });

      history.push("/users");

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function getDataForm() {
    const model = refForm.current?.getData();

    const data: any = {
      name: model?.name,
      username: model?.username,
      dtBirth: model?.dtBirth,
      document: model?.document,
      mail: model?.mail,
      role: role?.value,
      //funds: fundsSelected.map(f => f.value)
    };

    return data;
  }

  function selectFund(funds: OptionSelect[] | null) {
    if (funds) {
      setFoundsSelected(funds);
      return;
    }

    setFoundsSelected([]);
  }

  return (
    <Form onSubmit={() => {}} ref={refForm}>
      <Col>
        <Card style={{ padding: 16, minHeight: "100%" }}>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col md={2}>
              <Link to="/users">
                <Button
                  type="button"
                  style={{ width: "100%" }}
                  className={`btn btn-primary`}
                  icon="fas fa-undo"
                  title="Voltar"
                  disabled={loading}
                />
              </Link>
            </Col>
            <Col md={2}>
              <Button
                type="button"
                style={{ width: "100%" }}
                className={`btn btn-success`}
                icon={loading ? "fas fa-spinner fa-spin" : "fas fa-save"}
                disabled={loading}
                title="Gravar"
                onClick={save}
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col md={5}>
              <label>Nome</label>
              <Input type="text" name="name" />
            </Col>
            <Col md={4}>
              <label>Username</label>
              <Input type="text" name="username" />
            </Col>
            <Col md={3}>
              <label>Data Nasc.</label>
              <Input type="date" name="dtBirth" />
            </Col>
          </Row>

          <br />

          <Row>
            <Col md={3}>
              <label>CPF/CNPJ</label>
              <Input
                type="text"
                name="document"
                value={documentMask(document)}
                onChange={(event) => setDocument(event.currentTarget.value)}
                maxlength={18}
              />
            </Col>
            <Col>
              <label>Email</label>
              <Input type="text" name="mail" />
            </Col>

            <Col>
              <label>Acesso</label>
              <Select
                value={role}
                options={roleOptions}
                onChange={(option) => setRole(option)}
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col>
              <label>Fundos</label>
              <Select
                value={fundsSelected}
                options={fundOptions}
                isMulti={true}
                onChange={(options: any) => selectFund(options)}
              />
            </Col>
          </Row>

          <br />
        </Card>
      </Col>
    </Form>
  );
};
