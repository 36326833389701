import React from "react";

import { BrowserRouter } from "react-router-dom";
import { Routes } from "./main/routes/index.routes";
import { I18nProvider } from "./_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "./_metronic/layout";

function App() {
  return (
    <React.Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <MaterialThemeProvider>
          <I18nProvider>
            <Routes />
          </I18nProvider>
        </MaterialThemeProvider>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
