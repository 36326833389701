import { ListUsers } from "../../../../presentation/pages/users/list-users";
import { makeRemoteDeleteUser } from "../../usecases/users/remote-delete-user";
import { makeRemoteUpdatePasswordUser } from "../../usecases/users/remote-update-user-password-factory";

export const makeListUserPage = () => {
  return (
    <ListUsers
      remoteDeleteUser={makeRemoteDeleteUser()}
      remoteUpdateUserPassword={makeRemoteUpdatePasswordUser()}
    />
  );
};
