import { useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { routes, Route } from '../../../../../main/routes/routes'
import { checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }: any) {
  const location = useLocation();

  const getMenuItemActive = useCallback((url: any, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  }, [location]);

  const renderMenu = useCallback((route: Route, index: number) => {
    return (
      route.path ?
        route.pages ?
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(route.path, false)}`}
            data-menu-toggle="hover"
            key={index}
          >
            <NavLink className="menu-link menu-toggle" to={route.path}>
              <span className="svg-icon menu-icon">
                <i className={route.icon}></i>
              </span>
              <span className="menu-text">{route.namePage}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent">
                  <span className="menu-link">
                    <span className="menu-text">{route.namePage}</span>
                  </span>
                </li>

                {route.pages.map((page, index) => {
                  if (page.aside === false)
                    return null
                  else
                    return (
                      <li
                        key={index}
                        className={`menu-item ${getMenuItemActive(page.path, false)}`}
                      >
                        {/* //@ts-ignore */}
                        <NavLink className="menu-link" to={page.path ? page.path : ""}>
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">{page.namePage}</span>
                        </NavLink>
                      </li>
                    )
                })}

              </ul>
            </div>
          </li>
          :
          <li
            style={
              {
                display: (route.aside === false ? 'none' : 'inherit')
              }}
            key={index}
            className={`menu-item ${getMenuItemActive(route.path, false)}`}
          >
            <NavLink className="menu-link" to={route.path}>
              <span className="svg-icon menu-icon">
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} /> */}
                <i className={route.icon}></i>
              </span>
              <span className="menu-text">{route.namePage}</span>
              {route.parent !== undefined && <span style={{ display: 'none' }} className="menu-text-parent">{route.parent}</span>}
            </NavLink>
          </li>
        :
        <li key={index} className="menu-section ">
          <h4 className="menu-text" style={{ opacity: '0.5' }}>{route.namePage}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
    )
  }, [getMenuItemActive])

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {routes.map((route, index) => renderMenu(route, index))}
      </ul>
    </>
  );
}
