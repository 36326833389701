import { LogonAuth } from "../../../domain/usecases/auth/logon-auth";
import { ErrorsValidade } from "../../../domain/validate/errors-validade";
import { HttpClient, HttpStatusCode, HttpMethod, HttpResponsePattern } from "../../protocols/http/http-client";

export class RemoteLogon implements LogonAuth {
    private errors = new ErrorsValidade()

    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<LogonAuth.Model>
    ) { }

    async execute({ username, password }: LogonAuth.Params): Promise<HttpResponsePattern<LogonAuth.Model>> {
        const basicAuth = `Basic ${btoa(username + ":" + password)}`

        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: HttpMethod.POST,
            headers: { 'Authorization': basicAuth }
        })
        
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
                return httpResponse.body
            case HttpStatusCode.badRequest:
                throw this.errors.badRequest(httpResponse.body.message)
            case HttpStatusCode.unauthorized:
                throw this.errors.unauthorized()
            case HttpStatusCode.notFound:
                throw this.errors.badRequest("Usuario ou Senha inválido")
            default:
                throw this.errors.unexpected()
        }
    }
}