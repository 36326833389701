import { HttpClient, HttpMethod, HttpResponsePattern, HttpStatusCode } from "../../protocols/http/http-client";
import { GetUser } from "../../../domain/usecases/users/get-users";
import { ErrorsValidade } from "../../../domain/validate/errors-validade";
import { makeBearerToken } from "../../../main/factories/shared/makeBearerToken";

export class RemoteGetUser implements GetUser.Domain {
    private errors = new ErrorsValidade()

    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient<GetUser.Model>
    ) {}

    async execute({ id }: GetUser.Params): Promise<HttpResponsePattern<GetUser.Model>> {
        const httpResponse = await this.httpClient.request({
            url: this.url + "/" + id,
            method: HttpMethod.GET,
            headers: makeBearerToken(),
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
                return httpResponse.body
            case HttpStatusCode.badRequest:
                throw this.errors.badRequest(httpResponse.body.message)
            case HttpStatusCode.unauthorized:
                throw this.errors.unauthorized()
            case HttpStatusCode.notFound:
                throw this.errors.forbidden(httpResponse.body.message)
            default:
                throw this.errors.unexpected()
        }
    }
}