export interface ButtonType {
  icon?: string,
  title?: string,
  testid?: string,
  className?: string,
  color?: 'success' | 'primary' | 'danger' | 'warning' | 'info' | 'clean' | 'secondary'
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  disabled?: boolean
}

export const Button = ({ icon, title, testid, className, onClick, color, type, style, disabled }: ButtonType) => {

  let btnColor = color ? `btn-${color}` : 'btn-primary'

  let btnIcon = icon ? <i className={icon} style={title === undefined ? { paddingRight: 0 } : {}} /> : null

  return (
    <button
      type={type}
      className={`btn ${btnColor} ${className}`}
      data-testid={testid}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {btnIcon}
      {" "}
      {title}
    </button>
  )
}
