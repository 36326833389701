import { makeCreateUserPage } from '../factories/presentation/users/create-users-page-factory'
import { makeListUserPage } from '../factories/presentation/users/list-users-page'

export interface Route {
    namePage: string,
    component?: React.FC<any> | JSX.Element,
    path?: string,
    icon?: string,
    aside?: boolean,
    permissions?: string[],
    parent?: string,
    pages?: Route[]
}

export const routes: Route[] = [
    {
        namePage: 'Editar usuário',
        component: makeCreateUserPage(),
        path: '/users/edit/:id',
        icon: 'fas fa-users',
        permissions: [],
        aside: false
    },
    {
        namePage: 'Adicionar usuário',
        component: makeCreateUserPage(),
        path: '/users/add',
        icon: 'fas fa-users',
        permissions: [],
        aside: false
    },
    {
        namePage: 'Usuarios',
        component: makeListUserPage(),
        path: '/users',
        icon: 'fas fa-users',
        permissions: []
    },
]