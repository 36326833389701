import swal from "sweetalert2"

export class ErrorsValidade {

    ok(title: string, message: string) {
        swal.fire({
            title,
            text: message,
            icon: "success",
            width: 300,
            timer: 3000,
        });
    }

    badRequest(message: string) {
        swal.fire({
            title: 'Atenção',
            text: message,
            icon: 'warning',
            width: 300,
            timer: 3000,
        });
    }

    unauthorized() {
        window.location.href = "/auth/login"
        sessionStorage.removeItem('jwt')

        swal.fire({
            title: "Tempo limite de sessão expirado",
            text: "Seu tempo limite de sessão expirou, realize seu logon novamente",
            icon: "info",
            width: 300,
            timer: 3000,
        });
    }

    forbidden(message: string) {
        swal.fire({
            title: 'Atenção',
            text: message,
            icon: 'warning',
            width: 300,
            timer: 3000,
        });
    }

    unexpected() {
        swal.fire({
            title: "Ops... um erro ocorreu",
            text: "por favor tente novamente mais tarde",
            icon: "error",
            width: 300,
            timer: 3000,
        });
    }
}