import { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Form } from "@unform/web"
import { FormHandles } from "@unform/core";

import { Input } from "../../components/input"

import { useGlobalStoreContext } from "../../../store/store"
import { makeRemoteLogon } from "../../../main/factories/usecases/auth/auth-factory";

type InitialValuesType = {
    user: string,
    password: string
}

const InitialValues: InitialValuesType = {
    user: "",
    password: ""
}

export const Login = () => {
    const { setStore } = useGlobalStoreContext()
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [loading, setLoading] = useState(false)
    const [initialData] = useState(InitialValues)

    useEffect(() => {
        let info: any = sessionStorage.getItem('info')

        if (info) {
            info = JSON.parse(atob(info))

            if (info.user)
                formRef.current?.setData({ ...initialData, user: info.user })

        }

    }, [initialData])

    const handleSubmit = async (formData: InitialValuesType) => {
        try {
            formRef.current?.setErrors({});

            if (!formData.user) {
                formRef.current?.setFieldError('user', 'Favor preencher o campo')
                formRef.current?.getFieldRef('user').focus()
                return
            }

            if (!formData.password) {
                formRef.current?.setFieldError('password', 'Favor preencher o campo')
                formRef.current?.getFieldRef('password').focus()
                return
            }

            setLoading(true)

            const remoteLogon = makeRemoteLogon();
            remoteLogon
                .execute({
                    username: formData.user,
                    password: formData.password
                })
                .then(response => {
                    const { token, user } = response.data

                    setLoading(false)

                    const info = btoa(JSON.stringify({ user: formData.user }))
                    sessionStorage.setItem('info', info)
                    sessionStorage.setItem('jwt', token)

                    setStore({
                        token: token,
                        user: {
                            nome: user.name,
                            username: formData.user
                        }
                    })

                    setTimeout(() => {
                        history.push("/users")
                    }, 1000)
                })
                .catch(error => {
                    setLoading(false)
                });

        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <div className="d-flex flex-column-fluid flex-column flex-center">
            <Form
                className="login-form login-signin py-11"
                onSubmit={handleSubmit}
                ref={formRef}
            >
                <div className="text-center pb-8">
                    <h3 className="font-weight-bolder text-dark font-size-h3 font-size-h3-lg">Bem Vindo!</h3>
                </div>
                <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Usuário</label>
                    <Input
                        className="h-auto py-7 px-6 rounded-lg bg-secondary"
                        solid
                        type="text"
                        name="user"
                        //@ts-ignore
                        autocomplete="off"
                        textError={true}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">Senha</label>
                    </div>
                    <Input
                        className="h-auto py-7 px-6 rounded-lg bg-secondary"
                        solid
                        type="password"
                        name="password"
                        //@ts-ignore
                        autocomplete="off"
                        textError={true}
                        onKeyDown={(event) => event.key === 'Enter' && handleSubmit}
                    />
                </div>
                <div className="text-center">
                    <Link to="/auth/forgot">
                        <span className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 text-center" id="kt_login_forgot">Esqueceu sua senha ?</span>
                    </Link>
                </div>
                <div className="text-center pt-2">
                    <button className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">
                        <span>Entrar</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </Form>
            <div>
                {/* <OverlayTrigger key={language.name} overlay={<Tooltip id="tooltip-disabled">{language.name}</Tooltip>}>
                    <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                        <img
                            className="h-20px w-20px rounded"
                            src={language.flag}
                            alt={language.name}
                        />
                    </div>
                </OverlayTrigger> */}

            </div>
        </div>
    );
}
