import React,
{
    ChangeEventHandler,
    FocusEventHandler,
    MouseEventHandler,
    KeyboardEventHandler,
    useEffect,
    useRef
} from "react";

import { useField } from '@unform/core'

export interface InputSolidProps {
    inputIcon?: string,
    solid?: boolean,
    type: string,
    className?: string,
    placeholder?: string
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    name: string,
    value?: string,
    defaultValue?: string,
    textError?: boolean,
    onFocus?: FocusEventHandler,
    onClick?: MouseEventHandler,
    step?: string,
    list?: string,
    containerStyle?: object,
    onKeyDown?: KeyboardEventHandler
    innerRef?: React.RefObject<HTMLInputElement>,
    disabled?: boolean,
    id?: string,
    min?: number,
    max?: number,
    testid?: string,
    maxlength?: number
}

export const Input = React.forwardRef<HTMLInputElement, InputSolidProps>((props: InputSolidProps, innerRef) => {
    const refInput = useRef<HTMLInputElement>(null)
    const { fieldName, registerField, defaultValue, error } = useField(props.name)

    let classes = [
        'form-control'
    ];

    if (props.className) {
        classes.push(props.className);
    }

    if (props.solid) {
        classes.push('form-control-solid');
    }

    if (error) {
        classes.push('is-invalid');
    }

    let input = (
        <input
            onChange={props.onChange}
            onBlur={props.onBlur}
            type={props.type}
            placeholder={props.placeholder}
            ref={innerRef ? innerRef : refInput}
            name={props.name}
            className={classes.join(' ')}
            defaultValue={defaultValue}
            onFocus={props.onFocus}
            list={props.list}
            autoComplete='off'
            step={props.step}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
            value={props.value}
            disabled={props.disabled}
            id={props.id}
            min={props.min}
            max={props.max}
            maxLength={props.maxlength}
        />
    );

    useEffect(() => {
        !innerRef &&
            registerField({
                name: props.name,
                ref: refInput.current,
                path: 'value'
            })
    }, [fieldName, registerField, props.name, innerRef])

    if (props.inputIcon) {
        return (
            <div className="input-icon" style={{ ...props.containerStyle }}>
                {input}
                <span>
                    <i className={props.inputIcon}></i>
                </span>
                {props.textError && <span style={{ fontSize: 11 }} className="form-text text-danger">{error}</span>}
            </div>
        );
    }

    return (
        <div >
            {input}
            {props.textError && <span style={{ fontSize: 11 }} className="form-text text-danger">{error}</span>}
        </div>
    );
});
