import { Redirect, Switch } from "react-router-dom";

import { routes } from './routes';
import { ContentRoute } from "../../_metronic/layout";

export default function BasePage() {

  const renderRoutes = (route: any) => {
    if (route.pages) {
      return route.pages.map((page: any) => {
        /* @ts-ignore */
        return <ContentRoute
          key={page.namePage}
          path={page.path}
          component={page.component}
        />
      })
    }
    return (
      /* @ts-ignore */
      <ContentRoute
        key={route.namePage}
        path={route.path}
        component={route.component}
      />
    )
  }

  return (
    <Switch>

      {
        routes.map((route) => renderRoutes(route))
      }

      <Redirect exact from="/" to="/users" />
      <Redirect to="/users" />

    </Switch>
  );
}
