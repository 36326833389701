import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap'
import * as CSS from 'csstype';

export interface RowProps {
  name: string | JSX.Element,
  refName: string,
  sorteble?: boolean,
  style?: CSS.Properties,
  cell: (props: CellProps) => JSX.Element,
  type?: 'datetime' | string
  typeDateFormat?: 'dd-MM-yyyy' | 'MM-dd-yyyy' | 'yyyy-MM-dd'
}

interface CellProps {
  index: number,
  rowIndex: number
}

interface TableProps {
  values: any[],
  limit?: number,
  pagination: boolean,
  rows: (row: any) => RowProps[],
  viewLimit?: boolean,
  id?: string,
  paginationSelected?: (page: number) => void,
  defaultPage?: number,
}

export const Table: React.FC<TableProps> = (props) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [sortClick, setSortClick] = useState({ headerName: '', click: 0 })
  const [searchBy, setSearchBy] = useState('')
  const [headerName, setHeaderName] = useState('')
  const [limitPage, setLimitPage] = useState(props.limit ? props.limit : 0)

  const [originList, setOriginList] = useState<RowProps[]>([])

  const maxPage = limitPage && (props.values.length % limitPage) !== 0 ? (props.values.length / limitPage) + 1 : limitPage ? props.values.length / limitPage : props.values.length

  useEffect(() => {
    if (props.paginationSelected) {
      props.paginationSelected(selectedPage)
    }
  }, [selectedPage, props])

  useEffect(() => {
    setTimeout(() => {
      if (props.defaultPage) {
          setSelectedPage(props.defaultPage)
    }
    }, 100)
}, [props.defaultPage])

  useEffect(() => {
    let newOriginList = JSON.parse(JSON.stringify(props.values))
    if (newOriginList.length !== originList.length) {
      setSelectedPage(1)
    }

    setOriginList(newOriginList)

  }, [originList.length, props.values])



  function renderPagination(values: any) {
    const pagination = [];

    if (limitPage === 0) {
      pagination.push(
        <li >
          <a href="/#" onClick={(event) => event.preventDefault()} className={`datatable-pager-link datatable-pager-link-number datatable-pager-link-active`}>1</a>
        </li>
      )
    } else {

      const rest = values.length % limitPage;
      let qtdPage = values.length / limitPage;

      qtdPage = rest !== 0 ? qtdPage + 1 : qtdPage

      for (let i = 1; i <= qtdPage; i++) {
        pagination.push(
          <li onClick={() => setSelectedPage(i)} key={i}>
            <a href="/#" onClick={(event) => event.preventDefault()} className={`datatable-pager-link datatable-pager-link-number ${selectedPage === i && "datatable-pager-link-active"}`}>{i}</a>
          </li>
        )
      }
    }

    if ((selectedPage - 4) < 0)
      return pagination.filter((page, index) => index < 5);
    else
      return pagination.filter((page, index) => (index + 1) >= (selectedPage - 2) && (index + 1) <= (selectedPage + 2));
  }

  function filterValues(index: any) {
    if (limitPage) {
      let limit = limitPage ? limitPage : 0

      const maxPage = (selectedPage * limit) - 1
      const minPage = (selectedPage * limit) - limit
      if (index >= minPage && index <= maxPage)
        return true

      return false
    }
    return true
  }

  function renderIcon(headerName: string) {
    let icon = <i className="fas fa-arrows-alt-v" style={{ color: "#b5b6c7", fontSize: "10px", paddingLeft: 10 }}></i>

    if (headerName === sortClick.headerName && sortClick.click > 0) {
      if (sortClick.click === 1)
        icon = <i className="fas fa-arrow-up" style={{ fontSize: "10px", color: '#3699FF', paddingLeft: 10 }}></i>
      else
        icon = <i className="fas fa-arrow-down" style={{ fontSize: "10px", color: '#3699FF', paddingLeft: 10 }}></i>
    }

    return icon
  }

  function sortValues(headerName: string) {
    setHeaderName(headerName)
    if (headerName === sortClick.headerName) {
      if (sortClick.click === 2) {
        setSearchBy('reset-current')
        setSortClick({ ...sortClick, click: 0 })
      } else {
        if (sortClick.click === 0) {
          setSearchBy('asc')
        } else {
          setSearchBy('desc')
        }

        setSortClick({ ...sortClick, click: sortClick.click + 1 })
      }
    } else {
      setSearchBy('reset-new')
      setSortClick({ headerName, click: 1 })
    }
  }

  let filtered = () => {
    let row = props.values.length > 0 ? props.values[0] : null
    let cells: RowProps[]
    if (row)
      cells = props.rows(row)

    switch (searchBy) {
      case 'reset-current':
        return originList;
      case 'asc':
        return props.values.sort((a: any, b: any) => {
          let cell = cells.find(c => c.refName === headerName)

          if (cell && cell.type === 'datetime' && cell.typeDateFormat) {
            let date1 = dateFormat(a[headerName], cell.typeDateFormat)
            let date2 = dateFormat(b[headerName], cell.typeDateFormat)

            if (date1 < date2) {
              return - 1
            }
            if (date1 > date2) {
              return 1
            }
            return 0
          }

          if (a[headerName] < b[headerName]) {
            return - 1
          }
          if (a[headerName] > b[headerName]) {
            return 1
          }
          return 0
        })
      case 'desc':
        return props.values.sort((a: any, b: any) => {
          let cell = cells.find(c => c.refName === headerName)

          if (cell && cell.type === 'datetime' && cell.typeDateFormat) {
            let date1 = dateFormat(a[headerName], cell.typeDateFormat)
            let date2 = dateFormat(b[headerName], cell.typeDateFormat)

            if (date1 > date2) {
              return - 1
            }
            if (date1 < date2) {
              return 1
            }
            return 0
          }

          if (a[headerName] > b[headerName]) {
            return - 1
          }
          if (a[headerName] < b[headerName]) {
            return 1
          }
          return 0
        })
      case 'reset-new':
        return props.values.sort((a: any, b: any) => {
          let cell = cells.find(c => c.refName === headerName)

          if (cell && cell.type === 'datetime' && cell.typeDateFormat) {
            let date1 = dateFormat(a[headerName], cell.typeDateFormat)
            let date2 = dateFormat(b[headerName], cell.typeDateFormat)

            if (date1 < date2) {
              return - 1
            }
            if (date1 > date2) {
              return 1
            }
            return 0
          }

          if (a[headerName] < b[headerName]) {
            return - 1
          }
          if (a[headerName] > b[headerName]) {
            return 1
          }
          return 0
        })
      default: return originList
    }
  }

  function renderRows(item: any, rowIndex: number) {
    let cells: RowProps[] = props.rows(item)

    return (
      <tr
        className="datatable-row"
        style={{ left: 0, height: 45 }}
        key={rowIndex}
      >
        {
          cells.map((item, index) =>
            item.cell({ index, rowIndex })
          )
        }
      </tr>
    )
  }

  function renderHeader(item: any, rowIndex: number) {
    let cells: RowProps[] = props.rows(item)

    return (
      <tr className="datatable-row" style={{ left: 0 }} key={rowIndex}>
        {
          cells.map((item, index) =>
            <th
              key={index}
              className={`datatable-cell datatable-toggle-sor`}
              onClick={() => item.sorteble === true && sortValues(item.refName)}
              style={{
                color: item.refName === sortClick.headerName && sortClick.click > 0 ? '#3699FF' : '#B5B5C3',
                fontSize: '0.9rem',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: '0.1rem',
                cursor: 'pointer',
                height: 45,
                ...item.style
              }}
            >
              <span
                style={{
                  width: 173,
                }}
              >
                {item.name}
                {item.sorteble === true && renderIcon(item.refName)}
              </span>
            </th>
          )
        }
      </tr>
    )
  }

  let values = filtered()!;

  return (
    <Col>
      <div className="datatable datatable-bordered datatable-head-custom 
        datatable-default datatable-primary datatable-loaded"
      >

        <div className="table-responsive" style={{ width: '101%' }}>
          <table style={{ width: '100%' }} id={props.id}>
            <thead className="datatable-head">
              {values.filter((item, index) => index === 0).map((item: any, index) => (
                renderHeader(item, index)
              ))}
            </thead>
            <tbody className="datatable-body">
              {values.filter((item: any, index: any) => filterValues(index)).map((item: any, index) => (
                renderRows(item, index)
              ))}

              {props.children}
            </tbody>
          </table>
          {
            values.length > 0 && props.viewLimit !== false &&
            <>
              <br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '3rem' }}>
                <select
                  value={limitPage}
                  className="form form-control form-control-solid mr-2"
                  style={{ height: '2.25rem', width: '75px', paddingTop: '0px', paddingBottom: '0px' }}
                  onChange={(e) => { setLimitPage(Number(e.currentTarget.value)); setSelectedPage(1) }}
                >
                  {
                    optionsPage.map((item, index) =>
                      <option value={item.value} key={index}>{item.name}</option>
                    )
                  }
                </select>
                <span>Mostrando {(selectedPage * (limitPage ? limitPage : 0)) - (limitPage ? limitPage : 0) + 1} - {(selectedPage * (limitPage === 0 ? 1 : limitPage))} de {values.length}</span>
              </div>
            </>
          }
        </div>

        {(props.pagination && values.length) > 0 && (
          <div className="datatable-pager datatable-paging-loaded" style={{ padding: 0 }}>
            <ul className="datatable-pager-nav my-2 mb-sm-0" style={{ margin: 'auto' }}>
              <li onClick={() => setSelectedPage(1)} >
                <a href={"/#"} onClick={(event) => event.preventDefault()} className="datatable-pager-link datatable-pager-link-first">
                  <i className="flaticon2-fast-back">
                  </i>
                </a>
              </li>
              <li onClick={() => selectedPage > 1 && setSelectedPage(selectedPage - 1)}>
                <a href={"/#"} onClick={(event) => event.preventDefault()} className="datatable-pager-link datatable-pager-link-prev">
                  <i className="flaticon2-back">
                  </i>
                </a>
              </li>

              {
                renderPagination(props.values)
              }

              <li onClick={() => selectedPage < (maxPage - 1) && setSelectedPage(selectedPage + 1)}>
                <a href={"/#"} onClick={(event) => event.preventDefault()} className="datatable-pager-link datatable-pager-link-next">
                  <i className="flaticon2-next">
                  </i>
                </a>
              </li>
              <li onClick={() => setSelectedPage(Math.trunc(maxPage))}>
                <a href={"/#"} onClick={(event) => event.preventDefault()} className="datatable-pager-link datatable-pager-link-last">
                  <i className="flaticon2-fast-next">
                  </i>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </Col>
  )
};

const optionsPage = [
  { value: 5, name: '5' },
  { value: 10, name: '10' },
  { value: 20, name: '20' },
  { value: 30, name: '30' },
  { value: 50, name: '50' },
  { value: 100, name: '100' },
  { value: 0, name: 'todos' },
]

function dateFormat(date: string, pattern: string) {
  let dateArr = date.includes('/') ? date.split('/') : date.split('-')
  let hours = date.substring(10, date.length)
  let patternArr = pattern.split('-')

  let day = dateArr[patternArr.findIndex(p => p === 'dd')]
  let month = dateArr[patternArr.findIndex(p => p === 'MM')]
  let year = dateArr[patternArr.findIndex(p => p === 'yyyy')]

  return month + '-' + day + '-' + year + ' ' + hours

}